import { ChangeEvent, JSX, useCallback, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { CopyButton, ImageX, notification } from '@ankr.com/chain-tools-ui';
import { LoadingButton, TextField } from '@ankr.com/ui';
import { Box, Button, Container, Typography } from '@mui/material';
import { isAddress } from 'web3-utils';

import {
  PROJECT_NAME,
  PROJECT_TITLE,
  RECEIVING_TOKEN_AMOUNT,
  RECEIVING_TOKEN_NAME,
} from '../../../common/const/values';
import { useLocaleMemo, useTranslation } from '../../../i18n';
import {
  IFaucetFormPayload,
  useClaimTokensMutation,
} from '../../api/claimTokens';
import { faucetTranslation } from '../../translation';
import imgUrl from './assets/faucet.png';
import imgUrl2x from './assets/faucet@2x.png';
import tacMascotUrl from './assets/tac-mascot.png';
import tacMascotUrl2x from './assets/tac-mascot@2x.png';
import { useFaucetStyles } from './useFaucetStyles';

const CLAIM_RESPONSE_TX_STRING = 'Txhash: ';

export function Faucet(): JSX.Element {
  const { classes, cx } = useFaucetStyles();

  const { keys, t } = useTranslation(faucetTranslation);

  const { control, handleSubmit, setValue } = useForm<IFaucetFormPayload>({
    defaultValues: { address: '' },
  });

  const [claimTokens, { data: claimResponse, isLoading }] =
    useClaimTokensMutation();

  useEffect(() => {
    if (claimResponse?.msg && claimResponse?.status === 200) {
      notification({
        type: 'success',
        message: (
          <Box
            display="flex"
            gap={2}
            alignItems="center"
            flexDirection="column"
          >
            {claimResponse.msg}
            {claimResponse.msg.startsWith(CLAIM_RESPONSE_TX_STRING) && (
              <CopyButton
                text={claimResponse.msg.replace(CLAIM_RESPONSE_TX_STRING, '')}
                tooltipPlacement="top"
                renderButton={isCopied => (
                  <Button
                    size="small"
                    fullWidth
                    color={isCopied ? 'success' : 'primary'}
                  >
                    {isCopied ? t(keys.faucet.copied) : t(keys.faucet.copy)}
                  </Button>
                )}
              />
            )}
          </Box>
        ),
        autoClose: false,
        closeOnClick: false,
      });
    }
  }, [
    claimResponse?.msg,
    claimResponse?.status,
    keys.faucet.copied,
    keys.faucet.copy,
    t,
  ]);

  const onSubmit = useCallback(
    (payload: IFaucetFormPayload) => {
      void claimTokens(payload);
    },
    [claimTokens],
  );

  const handleAddressChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue('address', event.target.value.trim());
    },
    [setValue],
  );

  const titleBox = useLocaleMemo(() => {
    switch (PROJECT_NAME) {
      case 'TAC':
        return (
          <Box className={cx(classes.titleWrap, classes.tacTitleWrap)}>
            <ImageX
              imgUrl={tacMascotUrl}
              img2xUrl={tacMascotUrl2x}
              className={classes.tacMascot}
            />
            <Typography variant="h3">{PROJECT_TITLE}</Typography>
          </Box>
        );

      default:
        return (
          <Box className={classes.titleWrap}>
            <ImageX
              imgUrl={imgUrl}
              img2xUrl={imgUrl2x}
              className={classes.titleImg}
            />
            <Typography variant="h4" className={classes.title}>
              {t(keys.faucet.title)}
            </Typography>
          </Box>
        );
    }
  }, [
    classes.tacMascot,
    classes.tacTitleWrap,
    classes.title,
    classes.titleImg,
    classes.titleWrap,
    cx,
    keys.faucet.title,
    t,
  ]);

  return (
    <Container className={classes.container}>
      <Box className={classes.content}>
        {titleBox}
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          className={classes.form}
        >
          <Controller
            name="address"
            rules={{
              required: t('validation.required'),
              validate: v => isAddress(v) || t('validation.invalid-address'),
            }}
            control={control}
            render={({ field: { value }, fieldState: { error } }) => (
              <TextField
                helperText={error ? error.message : null}
                error={!!error}
                onChange={handleAddressChange}
                value={value}
                fullWidth
                placeholder={t(keys.faucet.addressPlaceholder)}
              />
            )}
          />

          <LoadingButton
            loading={isLoading}
            size="large"
            className={classes.submitBtn}
            type="submit"
          >
            {t(keys.faucet.button, {
              value: RECEIVING_TOKEN_AMOUNT,
              token: RECEIVING_TOKEN_NAME,
            })}
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
}
