import { CHAIN_LIST, IChainConfig } from '../types';

const ETH_DECIMALS = 18;

export const chainsConfig: Record<CHAIN_LIST, IChainConfig> = {
  [CHAIN_LIST.tacTestnet]: {
    chainId: '0x956',
    chainName: 'TAC Turin',
    nativeCurrency: {
      name: 'TAC',
      symbol: 'TAC',
      decimals: ETH_DECIMALS,
    },
    rpcUrls: ['https://turin.rpc.tac.build'],
    blockExplorerUrls: [
      'https://turin.explorer.tac.build',
      'https://turin.bd-exporer.tac.build',
    ],
  },
};
