/* eslint-disable @typescript-eslint/no-explicit-any */

type RequestProvider = {
  request: (params: { method: string; params?: any[] }) => Promise<any>;
};

export function isRequestProvider(
  provider: unknown,
): provider is RequestProvider {
  return !!provider && typeof provider === 'object' && 'request' in provider;
}
