import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';

import { AddChainButton } from '../../../AddChainButton';
import {
  HOMEPAGE,
  LOGO_LINK,
  PROJECT_NAME,
  PUBLIC_DIR,
} from '../../../common/const/values';
import { useHeaderStyles } from './useHeaderStyles';

export function Header() {
  const { classes } = useHeaderStyles();

  return (
    <Container className={classes.container}>
      <Link to={LOGO_LINK}>
        <img
          src={`${HOMEPAGE}${PUBLIC_DIR}/logo.svg`}
          alt={PROJECT_NAME}
          className={classes.logo}
        />
      </Link>

      <AddChainButton />
    </Container>
  );
}
