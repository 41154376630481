import React, { JSX, useMemo } from 'react';
import GoogleFontLoader from 'react-google-font';
import { useScrollToTop } from '@ankr.com/chain-tools-hooks';
import { incentivTheme, tacTheme } from '@ankr.com/chain-tools-themes';
import { Notifications, QueryLoadingAbsolute } from '@ankr.com/chain-tools-ui';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import { PROJECT_NAME } from '../../../common/const/values';
import { useLocaleInit } from '../../../i18n/hooks/useLocaleInit';
import { mainTheme } from '../../../themes/mainTheme';

interface IAppBaseProps {
  children: JSX.Element;
}

export const muiCache = createCache({
  key: 'mui',
});

function useTheme() {
  return useMemo(() => {
    switch (PROJECT_NAME) {
      case 'Incentiv':
        return incentivTheme;
      case 'TAC':
        return tacTheme;
      default:
        return mainTheme;
    }
  }, []);
}

export function AppBase({ children }: IAppBaseProps): JSX.Element {
  useScrollToTop();

  const { isLocaleReady } = useLocaleInit();
  const theme = useTheme();

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {PROJECT_NAME === 'TAC' && (
          <GoogleFontLoader
            fonts={[
              {
                font: 'Space Grotesk',
                weights: [400],
              },
              {
                font: 'Space Mono',
                weights: [400],
              },
            ]}
            subsets={['latin', 'latin-ext']}
            text=""
            display="swap"
          />
        )}
        {isLocaleReady ? children : <QueryLoadingAbsolute />}
        <Notifications />
      </ThemeProvider>
    </CacheProvider>
  );
}
