import { IChainConfig } from '../types';
import { connectWallet } from './connectWallet';

interface IError {
  message: string;
  code?: number;
}

const USER_REJECTED_ERROR_CODE = 4001;

const USER_REJECTED_ERROR_MSG = 'Network add denied by user';

const ADD_NETWORK_ERROR_MSG =
  'Failed to add the network to the wallet. Please try again.';

/**
 * Add the network to the wallet
 *
 * For more information see the [docs](https://docs.metamask.io/guide/rpc-api.html#eth-requestaccounts)
 */
export async function addNetworkToWallet(
  chainConfig: IChainConfig,
): Promise<void> {
  const provider = await connectWallet();

  try {
    await provider.request({
      method: 'wallet_addEthereumChain',
      params: [chainConfig],
    });
  } catch (error) {
    const anyError = error as IError;
    if (anyError.code === USER_REJECTED_ERROR_CODE) {
      throw new Error(USER_REJECTED_ERROR_MSG);
    }

    throw new Error(`${ADD_NETWORK_ERROR_MSG}: ${anyError.message}`);
  }
}
