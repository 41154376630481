/* eslint-disable @typescript-eslint/no-explicit-any */

export type RequestProvider = {
  request: (params: { method: string; params?: any[] }) => Promise<any>;
};

export interface IChainConfig {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
  iconUrls?: string[];
}

export const CHAIN_LIST = {
  tacTestnet: 2390,
} as const;

export type CHAIN_LIST = (typeof CHAIN_LIST)[keyof typeof CHAIN_LIST];
