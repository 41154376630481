import { queryFnNotifyWrapper } from '@ankr.com/chain-tools-ui';
import { toChecksumAddress } from 'web3-utils';

import { faucetApi } from '../../api/faucetApi';
import { API_ENDPOINT } from '../../common/const/environment';

export interface IFaucetFormPayload {
  address: string;
}

interface IFaucetFormResponse {
  msg: string;
  status: number;
}

export const { useClaimTokensMutation } = faucetApi.injectEndpoints({
  endpoints: build => ({
    claimTokens: build.mutation<IFaucetFormResponse, IFaucetFormPayload>({
      queryFn: queryFnNotifyWrapper<
        IFaucetFormPayload,
        never,
        IFaucetFormResponse
      >(async (payload: IFaucetFormPayload) => {
        const response = await fetch(`${API_ENDPOINT}/claim`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify({
            address: toChecksumAddress(payload.address),
          }),
        });

        const result = await response.json();

        return {
          data: {
            msg: result.msg,
            status: response.status,
          },
        };
      }),
    }),
  }),
});
