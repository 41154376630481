import React from 'react';
import { Discord, ExternalLink, Telegram, XIcon } from '@ankr.com/ui';
import { Box, Button, Container, IconButton, Typography } from '@mui/material';

import {
  CONTRACT_VERIFIER_LINK,
  DISCORD_LINK,
  PROJECT_NAME,
  TG_LINK,
  TWITTER_LINK,
} from '../../../common/const/values';
import { useTranslation } from '../../../i18n';
import { footerTranslation } from './translation';
import { useFooterStyles } from './useFooterStyles';

export function Footer() {
  const { classes, cx } = useFooterStyles();

  const { keys, t } = useTranslation(footerTranslation);

  return (
    <Container className={classes.container}>
      <Box className={classes.item}>
        <Typography variant="body2" className={classes.copyright}>
          {t(keys.footer.copyright, {
            year: new Date().getFullYear(),
            project: PROJECT_NAME,
          })}
        </Typography>
      </Box>

      {CONTRACT_VERIFIER_LINK && (
        <Box className={cx(classes.item, classes.contractWrap)}>
          <Button
            variant="text"
            size="small"
            color="secondary"
            endIcon={<ExternalLink />}
            href={CONTRACT_VERIFIER_LINK}
            rel="noopener noreferrer"
            target="_blank"
          >
            {t(keys.footer.contractVerifier)}
          </Button>
        </Box>
      )}

      <Box className={cx(classes.item, classes.socialWrap)}>
        {DISCORD_LINK && (
          <IconButton
            size="small"
            color="secondary"
            className={classes.socialBtn}
            href={DISCORD_LINK}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Discord sx={{ color: 'inherit' }} />
          </IconButton>
        )}
        {TG_LINK && (
          <IconButton
            size="small"
            color="secondary"
            className={classes.socialBtn}
            href={TG_LINK}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Telegram sx={{ color: 'inherit' }} />
          </IconButton>
        )}
        {TWITTER_LINK && (
          <IconButton
            size="small"
            color="secondary"
            className={classes.socialBtn}
            href={TWITTER_LINK}
            rel="noopener noreferrer"
            target="_blank"
          >
            <XIcon sx={{ color: 'inherit' }} />
          </IconButton>
        )}
      </Box>
    </Container>
  );
}
