import { RequestProvider } from '../types';
import { isRequestProvider } from './isRequestProvider';

/**
 * Connects the injected wallet.
 *
 * @returns The wallet provider
 */
export async function connectWallet(): Promise<RequestProvider> {
  const provider = window.ethereum;

  if (!isRequestProvider(provider)) {
    throw new Error(
      'The ethereum wallet provider is not supported. Please make sure you have a compatible wallet installed.',
    );
  }

  await provider.request({
    method: 'eth_requestAccounts',
  });

  return provider;
}
