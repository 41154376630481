import React, { JSX } from 'react';
import { LoadingButton } from '@ankr.com/ui';

import { useTranslation } from '../i18n';
import { useAddChainButton } from './hooks/useAddChainButton';
import { translation } from './translation';
import { useAddChainButtonStyles } from './useAddChainButtonStyles';

export function AddChainButton(): JSX.Element | null {
  const { classes } = useAddChainButtonStyles();

  const { keys, t } = useTranslation(translation);

  const { chain, isLoading, onClick } = useAddChainButton();

  if (!chain) {
    return null;
  }

  return (
    <LoadingButton
      variant="contained"
      loading={isLoading}
      onClick={onClick}
      size="small"
      className={classes.button}
    >
      {t(keys.button)}
    </LoadingButton>
  );
}
