import { useCallback, useMemo, useState } from 'react';

import { CHAIN_ID } from '../../common/const/values';
import { CHAIN_LIST, IChainConfig } from '../types';
import { addNetworkToWallet } from '../utils/addNetworkToWallet';
import { chainsConfig } from '../utils/chainsConfig';

interface IUseAddChainButton {
  chain?: IChainConfig;
  isLoading: boolean;
  onClick: () => Promise<void>;
}

export function useAddChainButton(): IUseAddChainButton {
  const [isLoading, setIsLoading] = useState(false);

  const chain = useMemo(() => {
    if (CHAIN_ID) {
      const chainKey = Object.entries(CHAIN_LIST).find(
        ([, value]) => CHAIN_ID === value,
      );

      if (!!chainKey && !!chainKey[0]) {
        return chainsConfig[CHAIN_LIST[chainKey[0] as keyof typeof CHAIN_LIST]];
      }
      return undefined;
    }
    return undefined;
  }, []);

  const handleClick = useCallback(async () => {
    if (chain) {
      setIsLoading(true);

      try {
        await addNetworkToWallet(chain);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  }, [chain]);

  return {
    chain,
    isLoading,
    onClick: handleClick,
  };
}
