export const LOGO_LINK = '/';
export const HOMEPAGE = import.meta.env.VITE_HOMEPAGE;
export const PROJECT_TITLE = import.meta.env.VITE_TITLE;
export const PROJECT_NAME = import.meta.env.VITE_PROJECT_NAME;
export const PUBLIC_DIR = import.meta.env.VITE_PUBLIC_DIR;
export const CHAIN_ID = import.meta.env.VITE_CHAIN_ID
  ? Number(import.meta.env.VITE_CHAIN_ID)
  : undefined;
export const RECEIVING_TOKEN_NAME = import.meta.env.VITE_RECEIVING_TOKEN_NAME;
export const RECEIVING_TOKEN_AMOUNT = import.meta.env
  .VITE_RECEIVING_TOKEN_AMOUNT;
export const CONTRACT_VERIFIER_LINK = import.meta.env
  .VITE_CONTRACT_VERIFIER_LINK;
export const DISCORD_LINK = import.meta.env.VITE_DISCORD_LINK;
export const TG_LINK = import.meta.env.VITE_TG_LINK;
export const TWITTER_LINK = import.meta.env.VITE_TWITTER_LINK;
